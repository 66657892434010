import state from './moduleCourseState.js'
import mutations from './moduleCourseMutations.js'
import actions from './moduleCourseActions.js'
import getters from './moduleCourseGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

