import axios from '@/axios.js'

export default {
  fetchAll ({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.courses.length) {
        axios.get(`${process.env.VUE_APP_API_URL}/courses`)
          .then((response) => {
            commit('REFRESH', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      } else {
        resolve(state.courses)
      }
    })
  }
}
