<template>
  <div>
    <vs-popup class="popup50" title="Cadastrar Sala de Aula" :active.sync="popupAddClassroom">
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Nome da Sala" v-model="classroomEditable.class_name" data-vv-scope="classroom" data-vv-as="Nome Fantasia" v-validate.initial="'required'" name="class_name" />
          <span class="text-danger text-sm" v-show="errors.has('classroom.class_name')">{{ errors.first('classroom.class_name') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input type="number" class="w-full mt-0" label="Capacidade" v-model="classroomEditable.capacity" data-vv-scope="classroom" data-vv-as="Capacidade" v-validate="'required|min_value:1'" name="capacity" />
          <span class="text-danger text-sm" v-show="errors.has('classroom.capacity')">{{ errors.first('classroom.capacity') }}</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="storeClassroom" :disabled="!validateClassroomForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="popupAddClassroom = false">Cancelar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <vs-popup class="popup50" title="Editar Sala de Aula" :active.sync="popupEditClassroom">
      <div class="vx-row">
        <div class="vx-col md:w-3/4 w-full mt-0">
          <vs-input class="w-full mt-0" label="Nome da Sala" v-model="classroomEditable.class_name" data-vv-scope="classroom" data-vv-as="Nome Fantasia" v-validate.initial="'required'" name="class_name" />
          <span class="text-danger text-sm" v-show="errors.has('classroom.class_name')">{{ errors.first('classroom.class_name') }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mt-0">
          <vs-input type="number" class="w-full mt-0" label="Capacidade" v-model="classroomEditable.capacity" data-vv-scope="classroom" data-vv-as="Capacidade" v-validate="'required|min_value:1'" name="capacity" />
          <span class="text-danger text-sm" v-show="errors.has('classroom.capacity')">{{ errors.first('classroom.capacity') }}</span>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="updateClassroom" :disabled="!validateClassroomForm">Alterar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="popupEditClassroom = false">Cancelar</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>

    <vs-sidebar click-not-close parent="#schedule-practical" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
      <div v-if="localSchedules.length && flag === 'scheduleDetail'" class="mail-sidebar-content px-0 sm:pb-6 h-full">
        <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
              <h4>DETALHAMENTO DESTE ENCONTRO</h4>
            </div>
          </div>
          <div class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <!-- <vx-tooltip text="Imprimir" position="top" class="ml-4">
                <feather-icon icon="PrinterIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="$emit('markUnread')"></feather-icon>
              </vx-tooltip> -->
            </div>
          </div>
        </div>

        <component :is="scrollbarTag" class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6" :settings="settings" :key="$vs.rtl">

          <div v-if="isSidebarActive">
            <div class="vx-row">
              <div class="vx-col w-full">
                <schedule-card :schedule="localSchedules[0]" />
              </div>
            </div>

            <div class="vx-row" style="margin-top: 2.2rem">
              <div class="vx-col w-full">
                <vx-card>
                  <div class="vx-row">
                    <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
                      <h5 class="mb-1">Participantes do encontro</h5>
                      <!-- DROPDOWN -->
                      <vx-tooltip text="Alterar Status" position="top" class="ml-auto">
                        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                          <feather-icon icon="FolderIcon" svg-classes="h-6 w-6" class="mb-2"></feather-icon>
                          <vs-dropdown-menu class="w-52">
                            <vs-dropdown-item @click="updateStatus('Presente')">
                              <span class="flex items-center">
                                <div class="h-4 w-4 inline-block rounded-full mr-3 bg-success"></div>
                                <span>Presente</span>
                              </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="updateStatus('Ausente')">
                              <span class="flex items-center">
                                <div class="h-4 w-4 inline-block rounded-full mr-3 bg-danger"></div>
                                <span>Ausente</span>
                              </span>
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="updateStatus('Programada')">
                              <span class="flex items-center">
                                <div class="h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary"></div>
                                <span>Programada</span>
                              </span>
                            </vs-dropdown-item>

                          </vs-dropdown-menu>
                        </vs-dropdown>
                      </vx-tooltip>

                      <vx-tooltip text="Deletar Selecionados Deste Encontro" position="top" class="ml-4">
                        <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="confirmDelete"></feather-icon>
                      </vx-tooltip>

                    </div>
                  </div>

                  <vs-table v-if="localSchedules.length" :data="localSchedulesOrdered" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
                    <template slot="thead">
                      <vs-th style="font-size: 11px; width: 40px">
                        <vs-checkbox v-model="selectAllCheckBox" :disabled="!localSchedules.length" icon-pack="feather" :icon="selectAllIcon" class="select-all-chexkbox">Todos</vs-checkbox>
                      </vs-th>
                      <vs-th style="font-size: 11px;"><span>ALUNO</span></vs-th>
                      <vs-th style="font-size: 11px;"><span>INSTRUTOR</span></vs-th>
                      <vs-th style="font-size: 11px;"><span>STATUS</span></vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(schedule, indextr) in data" style="font-size: 12px; opacity: 1 !important">
                        <vs-td><vs-checkbox v-model="selectedSchedules" :vs-value="schedule" class="vs-checkbox-small ml-0 mr-1"></vs-checkbox></vs-td>
                        <vs-td><span class="cursor-default font-semibold">{{ schedule.student.name }}</span></vs-td>
                        <vs-td><span class="cursor-default font-semibold">{{ schedule.employee.name }}</span></vs-td>
                        <vs-td>
                          <span class="cursor-default font-semibold flex items-center" :class="`text-${schedule.status === 'Presente' ? 'success' : schedule.status === 'Ausente' ? 'danger' : schedule.status === 'Programada' ? 'secondary' : ''}`">
                            {{ schedule.status | scheduleStatus }}
                          </span>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vx-card>
              </div>
            </div>
          </div>
        </component>
      </div>
      <!-- MANIPULAR TURMA -->
      <div v-else class="mail-sidebar-content px-0 sm:pb-6 h-full">

        <div class="flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
          <div class="flex mb-4">
            <div class="flex items-center">
              <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar')" class="cursor-pointer hover:text-primary mr-4" svg-classes="w-6 h-6"></feather-icon>
              <h4>VOLTAR</h4>
            </div>
          </div>
          <div v-if="flag === 'updateTurma' || flag === 'detailsTurma'" class="ml-10 mb-4 mt-1">
            <div class="email__actions--single flex items-baseline">
              <vx-tooltip text="Deletar a Turma e Seus Agendamentos" position="top" class="ml-4">
                <feather-icon icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer hover:text-danger" @click="confirmDeleteTurma"></feather-icon>
              </vx-tooltip>
            </div>
          </div>
        </div>

        <div class="vx-row" style="margin-top: 0.5rem">
          <div class="vx-col w-full md:px-8 md:pb-8 pt-4 px-6 pb-6">
            <!-- EDITANDO TURMA -->
            <vx-card v-if="flag === 'updateTurma'">
              <div class="vx-row">
                <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid">
                  <h5 class="mb-1">Editando uma turma</h5>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-2">
                  <vs-input class="w-full mt-0" label="Nome da Turma" v-model="localTurma.name" data-vv-scope="updateTurma" data-vv-as="Nome da Turma" v-validate.initial="'required'" name="name" />
                  <span class="text-danger text-sm" v-show="errors.has('updateTurma.name')">{{ errors.first('updateTurma.name') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                  <label class="vs-input--label">Curso</label>
                  <v-select v-model="localTurma.course_id" :clearable="true" style="background-color: white;"
                    :reduce="option => option.value" :options="courses"
                    data-vv-scope="updateTurma" data-vv-as="Curso" v-validate.initial="'required'" name="course"
                    placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                  <span class="text-danger text-sm" v-show="errors.has('updateTurma.course')">{{ errors.first('updateTurma.course') }}</span>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-full w-full mt-2">
                  <label class="vs-input--label">Aluno(s)</label>
                  <v-select v-model="student" @input="addStudent" @search="debouncedGetSearchStudent" :clearable="true" :filterable="false" style="background-color: white;"
                    :options="studentOptions" placeholder="Digite CPF ou nome do aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>
              </div>

                <ul>
                  <li v-for="(student, idx) in sortStudentList" :key="idx">
                    <div class="mt-2 flex flex-wrap items-center">
                      <feather-icon @click="removeStudent(student)" icon="TrashIcon" svgClasses="hover:text-danger cursor-pointer stroke-current w-5 h-5" />
                      <p class="font-semibold mt-1 ml-2 mr-2">{{ student.data && student.data.name }}</p>
                      <small class="mt-1">{{ student.data && student.data.cpf }}</small>
                    </div>
                </li>
              </ul>

              <vs-divider></vs-divider>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="mt-4 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-4" @click="updateTurma" :disabled="!validateUpdateTurma">Alterar Turma</vs-button>
                  </div>
                </div>
              </div>

            </vx-card>
            <!-- NOVA TURMA -->
            <vx-card v-else-if="flag === 'newTurma'">
              <div class="vx-row">
                <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid">
                  <h5 class="mb-1">Criando uma nova turma</h5>
                </div>
              </div>

              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-2">
                  <vs-input class="w-full mt-0" label="Nome da Turma" v-model="localTurma.name" data-vv-scope="newTurma" data-vv-as="Nome da Turma" v-validate.initial="'required'" name="name" />
                  <span class="text-danger text-sm" v-show="errors.has('newTurma.name')">{{ errors.first('newTurma.name') }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-2">
                  <label class="vs-input--label">Curso</label>
                  <v-select v-model="localTurma.course_id" :clearable="true" style="background-color: white;"
                    :reduce="option => option.value" :options="courses"
                    data-vv-scope="newTurma" data-vv-as="Curso" v-validate.initial="'required'" name="course"
                    placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                  <span class="text-danger text-sm" v-show="errors.has('newTurma.course')">{{ errors.first('newTurma.course') }}</span>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-full w-full mt-2">
                  <label class="vs-input--label">Aluno(s)</label>
                  <v-select v-model="student" @input="addStudent" @search="debouncedGetSearchStudent" :clearable="true" :filterable="false" style="background-color: white;"
                    :options="studentOptions" placeholder="Digite CPF ou nome do aluno..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                </div>
              </div>
                <ul>
                  <li v-for="student in sortStudentList" :key="student.data.id">
                    <div class="mt-2 flex flex-wrap items-center">
                      <feather-icon @click="removeStudent(student)" icon="TrashIcon" svgClasses="hover:text-danger cursor-pointer stroke-current w-5 h-5" />
                      <p class="font-semibold mt-1 ml-2 mr-2">{{ student.data.name }}</p>
                      <small class="mt-1">{{ student.data.cpf }}</small>
                    </div>
                </li>
              </ul>

              <vs-divider></vs-divider>

              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="mt-4 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-4" @click="createTurma" :disabled="!validateNewTurma">Cadastrar Turma</vs-button>
                  </div>
                </div>
              </div>
            </vx-card>

            <!-- DETALHES DA TURMA -->
            <vx-card v-if="flag === 'detailsTurma'">
              <div class="vx-row">
                <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid">
                  <h5 class="mb-1">Turma {{ turmaVuex.name }} | {{ turmaVuex.course.name }}</h5>
                </div>
              </div>

              <vs-table v-if="turmaVuex.schedules" :data="groupedTurmaSchedules" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
                <template slot="thead">
                  <vs-th style="font-size: 11px;"><span>DATA</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>1ª HA</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>ÚLTIMA HA</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>QTD.</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>DISCIPLINA</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>INSTRUTOR</span></vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(schedule, indextr) in data" style="font-size: 12px; opacity: 1 !important">
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.date | dateTime }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.start_time.slice(0, 5) }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.end_time.slice(0, 5) }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.quantity }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.module.title }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ schedule.employee.name }}</span></vs-td>
                  </vs-tr>
                </template>
              </vs-table>


            </vx-card>

            <vx-card v-if="flag === 'handleClassroom'">
              <div class="vx-row">

                <div class="vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center">
                  <h5 class="mb-1">Gerenciamento de Salas de Aula</h5>
                    <vx-tooltip text="Cadastrar Sala de Aula" position="top" class="ml-4">
                      <feather-icon icon="PlusCircleIcon" svg-classes="h-6 w-6" class="cursor-pointer" @click="addClassroom"></feather-icon>
                    </vx-tooltip>
                </div>
              </div>

              <vs-table noDataText="Nenhuma Sala Cadastrada." :data="classrooms" class="bordered" hoverFlat style="overflow: -webkit-paged-y">
                <template slot="thead">
                  <vs-th style="font-size: 11px;"><span>SALA</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>CAPACIDADE</span></vs-th>
                  <vs-th style="font-size: 11px;"><span>AÇÃO</span></vs-th>
                </template>
                <template slot-scope="{data}">
                  <vs-tr :key="itr" v-for="(classroom, itr) in data" style="font-size: 12px; opacity: 1 !important">
                    <vs-td><span class="cursor-default font-semibold">{{ classroom.class_name }}</span></vs-td>
                    <vs-td><span class="cursor-default font-semibold">{{ classroom.capacity }}</span></vs-td>
                    <vs-td>
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editClassroom(classroom)" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteClassroom(classroom)" />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vx-card>
          </div>
        </div>

      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'
import ScheduleCard         from './TheoreticalClassCard.vue'
import _                    from 'lodash'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

import moduleCourse from '@/store/course/moduleCourse.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    schedules: {
      type: Array,
      required: true
    },
    turma: {
      type: Object,
      default: null
    },
    flag: {
      type: String,
      default: 'scheduleDetail'
    }
  },
  components: {
    VuePerfectScrollbar,
    ScheduleCard,
    vSelect
  },
  data () {
    return {
      showThread: false,
      selectedSchedules: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },
      localSchedules: [],
      studentOptions: [],

      student: null,
      localTurma: {
        name: null,
        course_id: null,
        students: []
      },
      popupAddClassroom: false,
      popupEditClassroom: false,
      classroomEditable: {
        class_name: '',
        capacity: 1
      }
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    if (!moduleCourse.isRegistered) {
      this.$store.registerModule('course', moduleCourse)
      moduleCourse.isRegistered = true
    }
    this.$store.dispatch('course/fetchAll')
  },
  watch: {
    async isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
          this.localTurma.name = null
          this.localTurma.course_id = null
          this.localTurma.students = []
          this.studentOptions = []
          this.student = null
          this.selectedSchedules = []
          this.localSchedules = []
        }, 500)
      } else {
        this.$validator.pause()
        //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
        this.$nextTick(() => {
          this.$validator.resume()
          this.$validator.errors.clear()
          this.$validator.validateScopes()
        })

        this.localSchedules = this.schedules
        if (this.turma && !this.localSchedules.length) {
          this.localTurma.id = this.turma.data.id
          this.localTurma.name = this.turma.data.name
          this.localTurma.course_id = this.turma.data.course_id

          this.localTurma.students = this.turma.data.students.reduce((a, b) => {
            a.push({ value: b.id, label: `${b.name} ${b.cpf || ''}`, data: b })
            return a
          }, [])
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    validateClassroomForm () {
      return !this.errors.any('classroom')
    },
    validateNewTurma () {
      return !this.errors.any('newTurma')
    },

    validateUpdateTurma () {
      return !this.errors.any('updateTurma')
    },
    classrooms () {
      return this.$store.state.classroom.classrooms
    },
    classroom () {
      return this.$store.state.classroom.classroom
    },
    turmaVuex () {
      return this.$store.state.turma.turma
    },
    groupedTurmaSchedules () {
      const result = _(this.turmaVuex.schedules).groupBy('start_date').map((schedules, date) => ({
        date,
        modules: _.groupBy(schedules, 'module_id')
      })).value()

      const finishedArray = []
      result.map(data => {
        Object.values(data.modules).map(m => {
          Object.values(_(m).groupBy('employee_id').value()).map(m => {
            finishedArray.push({
              date: m[0].start_date,
              start_time: m[0].start_time,
              end_time: m[m.length - 1].end_time,
              quantity: _(m).groupBy('start_time').size(),
              module: m[0].module,
              employee: m[0].employee
            })

            // console.log(m[0].module.title)
            // console.log('start_time', m[0].start_time)
            // console.log('start_date', m[0].start_date)
            // console.log('end_time', m[m.length - 1].end_time)
            // console.log('qtd', _(m).groupBy('start_time').size())
            // console.log('inst', m[0].employee.name)
            // console.log('------------------------------------')
          })
          // console.log('------------------------------------')
        })

        // Object.values(data.modules).map(m => {
        //   newArray.push({
        //     date: m[0].start_date,
        //     start_time: m[0].start_time,
        //     end_time: m[m.length - 1].end_time,
        //     quantity: _(m).groupBy('start_time').size(),
        //     module: m[0].module,
        //     employee: m[0].employee
        //   })

        //   console.log(m[0].module.title)
        //   console.log('start_time', m[0].start_time)
        //   console.log('start_date', m[0].start_date)
        //   console.log('end_time', m[m.length - 1].end_time)
        //   console.log('qtd', _(m).groupBy('start_time').size())
        //   console.log('inst', m[0].employee.name)
        //   console.log('------------------------------------')
        // })
      })

      return finishedArray
    },
    localSchedulesOrdered () {
      const schedules = JSON.parse(JSON.stringify(this.localSchedules))
      return schedules.sort((a, b) => { return a.student.name.localeCompare(b.student.name) })
    },
    sortStudentList () {
      const students = JSON.parse(JSON.stringify(this.localTurma.students))
      return students.sort((a, b) => { return a.data.name.localeCompare(b.data.name) })
    },
    selectAllCheckBox: {
      get () {
        return this.selectedSchedules.length
      },
      set (value) {
        if (value) {
          this.selectedSchedules = this.localSchedulesOrdered
        } else {
          this.selectedSchedules = []
        }
      }
    },
    selectAllIcon () {
      return this.selectedSchedules.length === this.localSchedules.length ? 'icon-check' : 'icon-minus'
    },
    courses () {
      return this.$store.getters['course/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    // classroom
    addClassroom () {
      this.classroomEditable = {
        class_name: '',
        capacity: 1
      }
      this.popupAddClassroom = true
    },
    async storeClassroom () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('classroom/store', this.classroomEditable)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.popupAddClassroom = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    editClassroom (classroom) {
      this.classroomEditable = JSON.parse(JSON.stringify(classroom))
      this.popupEditClassroom = true
    },
    async updateClassroom () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('classroom/update', this.classroomEditable)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.popupEditClassroom = false
      } catch (error) {
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    confirmDeleteClassroom (classroom) {
      this.$store.commit('classroom/SET', classroom)

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir esta sala?',
        accept: this.deleteClassroom,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteClassroom () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('classroom/delete', this.classroom.id)
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    async updateStatus (status) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_theoretical')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        this.$store.commit('scheduleTheoreticalClass/UPDATE_MASSIVE_SCHEDULES', { schedules: this.selectedSchedules, changes: { status, duration: '00:50'} })  // PRECISO COLOCAR ASSIM O TEMPO PQ NO BANCO É TIME
        this.selectedSchedules.map(a => {
          a.status = status
          a.duration = '00:50'
        })
        await this.$store.dispatch('scheduleTheoreticalClass/updateMany', this.selectedSchedules)
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    confirmDelete () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_theoretical')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Confirmar Exclusão',
          text: 'Tem certeza que deseja excluir os agendmentos selecionados?',
          accept: this.deleteSelected,
          acceptText: 'Excluir',
          cancelText: 'Cancelar'
        })
      }
    },
    async deleteSelected () {
      if (!this.selectedSchedules.length) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione ao menos um aluno.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }
      try {
        this.$vs.loading()
        const selectedSchedulesIds = this.selectedSchedules.map(a => a.id)
        await this.$store.dispatch('scheduleTheoreticalClass/delete', selectedSchedulesIds)
        selectedSchedulesIds.map(id => {
          const idx = this.localSchedules.findIndex(o => o.id === id)
          if (idx >= 0) this.localSchedules.splice(idx, 1)
        })
        this.selectedSchedules = []
        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        if (!this.localSchedules.length) this.$emit('closeSidebar')
      } catch (error) { console.error(error) }
    },
    //  TURMA
    addStudent () {
      const idx = this.localTurma.students.findIndex(a => a.data.id === this.student.data.id)
      if (this.student && idx < 0) this.localTurma.students.push(this.student)
      this.student = null
    },
    removeStudent (student) {
      const idx = this.localTurma.students.findIndex(a => a.data.id === student.data.id)
      if (idx >= 0) this.localTurma.students.splice(idx, 1)
    },
    async createTurma () {
      try {
        this.$vs.loading()
        const localTurma = JSON.parse(JSON.stringify(this.localTurma))
        localTurma.students = localTurma.students.map(a => a.value)
        await this.$store.dispatch('turma/store', localTurma)
        this.$emit('newTurma')
        this.$vs.loading.close()
        this.$emit('closeSidebar')
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Cadastrados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    async updateTurma () {
      // NO CASO E EXCLUSÃO DE UM USUÁRIO MANTENHO OS AGENDAMENTOS PASSADOS
      try {
        this.$vs.loading()
        const localTurma = JSON.parse(JSON.stringify(this.localTurma))
        localTurma.students = localTurma.students.map(a => a.value) // ids
        await this.$store.dispatch('turma/update', localTurma)
        this.$vs.loading.close()
        this.$emit('editTurma')
        this.$emit('closeSidebar')
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Alterados.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    confirmDeleteTurma () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir esta turma e TODOS os seus agendamentos?',
        accept: this.deleteTurma,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteTurma () {
      try {
        this.$vs.loading()
        await this.$store.dispatch('turma/delete', this.localTurma.id)
        this.$vs.loading.close()
        this.$emit('deleteTurma', this.localTurma.id)
        this.$emit('closeSidebar')
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) { console.error(error) }
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT', with: ['schedules'] })
          .then(function (response) {
            this.studentOptions = []
            for (const k in response.data) {
              this.studentOptions.push({
                value:  response.data[k].id,
                label:  `${response.data[k].name} ${response.data[k].cpf || ''}`,
                data:   response.data[k]
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    }
  }
}
</script>
<style lang="scss">
.popup50 .vs-popup {
  width: 50% !important;
}
</style>
