export default {
  forSelect (state) {
    const data = []
    for (const k in state.courses) {
      data.push({
        value: state.courses[k].id,
        label: `${state.courses[k].name} (${state.courses[k].class_hour}h)`,
        data: state.courses[k]
      })
    }
    return data
  }
}
