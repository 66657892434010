<template>
    <div id="schedule-practical" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
        <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
          <object :data="srcPdf" width="100%" style="height: 600px" />
        </vs-popup>

        <vs-sidebar class="items-no-padding" parent="#schedule-practical" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isEmailSidebarActive">
          <schedule-sidebar @closeSidebar="toggleEmailSidebar" />
        </vs-sidebar>

        <!-- MENU DE CONTEXTO -->
        <vue-context ref="menu">
          <!-- <li v-if="contextCopy">
            <a href="#" @click="contextMenuClicked('COPY')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="CopyIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Copiar Agendamento</span>
            </a>
          </li>
          <li v-if="contextPaste">
            <a href="#" @click="contextMenuClicked('PASTE')" class="flex items-center text-sm hover:text-primary">
              <feather-icon icon="FilePlusIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Colar Grupo</span>
            </a>
          </li> -->
          <li>
            <a href="#" @click="contextMenuClicked('DELETE')" class="flex items-center text-sm hover:text-danger">
              <feather-icon icon="MinusCircleIcon" svgClasses="w-5 h-5" />
              <span class="ml-2">Excluir Agendamento</span>
            </a>
          </li>
        </vue-context>

        <div :class="{'sidebar-spacer': clickNotClose}" class="no-scroll-content border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">

          <!-- SEARCH BAR AND MENU ICON -->
          <div class="flex border d-theme-dark-bg items-center">
              <feather-icon class="md:inline-flex lg:hidden ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleEmailSidebar(true)"/>
              <!-- <vs-input icon-no-border icon="icon-search" size="large" icon-pack="feather" placeholder="Search Mail" v-model="searchQuery" class="vs-input-no-border vs-input-no-shdow-focus w-full" /> -->
          </div>

          <!-- EMAIL ACTION BAR -->
          <div class="email__actions p-2 border border-r-0 border-l-0 border-solid d-theme-border-grey-light">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-0">

                <label class="vs-input--label">Turma</label>
                <div class="mt-0 flex flex-wrap items-center justify">
                  <v-select class="w-80__" v-model="turma" @input="changeTurma()" :clearable="true" :filterable="false" style="background-color: white;"
                    @search="debouncedGetSearchTurma" :reduce="option => option.value" :options="turmaOptions"
                    placeholder="Digite o nome da turma..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>
                  <vx-tooltip v-if="!turma" text="Criar Turma" position="top" class="mt-2">
                    <feather-icon @click="createTurma" icon="PlusCircleIcon" svgClasses="h-8 w-8" class="mt-0"></feather-icon>
                  </vx-tooltip>
                  <div v-else class="mt-1 flex flex-wrap items-center">
                    <vx-tooltip text="Editar a Turma Selecionada" position="top" class="mt-2">
                      <feather-icon @click="updateTurma" icon="EditIcon" svgClasses="h-6 w-6" class="mt-0"></feather-icon>
                    </vx-tooltip>
                    <vx-tooltip text="Ver a Turma Selecionada" position="top" class="mt-2 ml-2">
                      <feather-icon @click="seeTurma" icon="SearchIcon" svgClasses="h-6 w-6" class="mt-0"></feather-icon>
                    </vx-tooltip>
                  </div>
                </div>
              </div>


              <div class="vx-col md:w-1/2 w-full mt-0">
                <label class="vs-input--label">Disciplina</label>
                <v-select v-model="moduleCourse" :clearable="true" style="background-color: white;"
                  :reduce="option => option.value" :options="modules"
                  placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/3 w-full mt-0">
                <label class="vs-input--label">Instrutor</label>
                <v-select v-model="employee_id" @input="changeEmployee()" :reduce="option => option.value" :options="employees" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>
              <div class="vx-col md:w-1/3 w-full mt-0">
                <label class="vs-input--label">Sala</label>
                <v-select v-model="classroom" @input="changeClassroom()" :reduce="option => option.data" :options="classrooms" placeholder="Selecione" style="background-color: white;" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Desculpe, nenhum resultado encontrado.
                  </template>
                </v-select>
              </div>

              <div class="vx-col md:w-1/3 w-full mt-0">
                <div class="mt-4 flex flex-wrap items-center">

                  <!-- <span class="ml-6 font-semibold" style="font-size: 10px">
                    Contratado: {{ contractedSchedules }} <br>
                    Marcado: {{ scheduledClasses }}
                  </span>

                  <span class="ml-6 font-semibold" style="font-size: 10px">
                    Disponível: {{ availableSchedules }} <br>
                    Faltas: {{ absences }}
                  </span>
                  <span class="ml-6 font-semibold" style="font-size: 10px">
                    Noturno: {{ nightSchedules }} / {{ nightSchedulesRequired }} <br>
                     &nbsp;
                  </span> -->
                  <vx-tooltip text="Gerenciar Salas" position="top" class="mt-2 ml-2">
                    <feather-icon @click="handleClassroom" icon="PlusCircleIcon" svgClasses="h-8 w-8" class="mt-0"></feather-icon>
                  </vx-tooltip>

                  <vx-tooltip text="Período do Relatório" position="top" class="ml-auto mr-2 mt-1">
                    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                      <a href.prevent class="flex cursor-pointer">
                        <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 rounded-full" :class="[`text-primary`, 'mb-0']"
                          :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                        <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                      </a>
                      <vs-dropdown-menu class="w-60" style="z-index: 200001">
                        <vs-input type="date" v-model="reportFirstPeriod" class="w-full" label="Data Inicial" />
                        <vs-input type="date" v-model="reportLastPeriod" class="w-full" label="Data Final" />
                        <span class="text-danger text-sm" v-show="periodError">O período inicial é maior que o final.</span>
                      </vs-dropdown-menu>
                    </vs-dropdown>
                  </vx-tooltip>

                  <v-select class="w-48 mr-2" :clearable="false" v-model="orderBy" :reduce="option => option.value" style="background-color: white;"
                    :options="[
                      {value: 'date', label: 'Ordem Data'},
                      {value: 'student', label: 'Ordem Aluno'},
                      {value: 'employee', label: 'Ordem Profissional'},
                    ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>

                  <vx-tooltip text="Relatório Excel" position="top" class="mt-2 mr-2">
                    <svg @click="print('EXCEL')" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="svg-hover h-8 w-8 cursor-pointer" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M23 1.5q.41 0 .7.3q.3.29.3.7v19q0 .41-.3.7q-.29.3-.7.3H7q-.41 0-.7-.3q-.3-.29-.3-.7V18H1q-.41 0-.7-.3q-.3-.29-.3-.7V7q0-.41.3-.7Q.58 6 1 6h5V2.5q0-.41.3-.7q.29-.3.7-.3zM6 13.28l1.42 2.66h2.14l-2.38-3.87l2.34-3.8H7.46l-1.3 2.4l-.05.08l-.04.09l-.64-1.28l-.66-1.29H2.59l2.27 3.82l-2.48 3.85h2.16zM14.25 21v-3H7.5v3zm0-4.5v-3.75H12v3.75zm0-5.25V7.5H12v3.75zm0-5.25V3H7.5v3zm8.25 15v-3h-6.75v3zm0-4.5v-3.75h-6.75v3.75zm0-5.25V7.5h-6.75v3.75zm0-5.25V3h-6.75v3z" /></svg>
                  </vx-tooltip>
                  <vx-tooltip text="Relatório PDF" position="top" class="mt-2">
                    <svg @click="doRelatorioPdf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      class="svg-hover h-8 w-8 cursor-pointer" aria-hidden="true" focusable="false" width="0.86em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1536 1792"><path d="M1468 380q28 28 48 76t20 88v1152q0 40-28 68t-68 28H96q-40 0-68-28t-28-68V96q0-40 28-68T96 0h896q40 0 88 20t76 48zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384 1528V640H992q-40 0-68-28t-28-68V128H128v1536h1280zm-514-593q33 26 84 56q59-7 117-7q147 0 177 49q16 22 2 52q0 1-1 2l-2 2v1q-6 38-71 38q-48 0-115-20t-130-53q-221 24-392 83q-153 262-242 262q-15 0-28-7l-24-12q-1-1-6-5q-10-10-6-36q9-40 56-91.5t132-96.5q14-9 23 6q2 2 2 4q52-85 107-197q68-136 104-262q-24-82-30.5-159.5T657 552q11-40 42-40h22q23 0 35 15q18 21 9 68q-2 6-4 8q1 3 1 8v30q-2 123-14 192q55 164 146 238zm-576 411q52-24 137-158q-51 40-87.5 84t-49.5 74zm398-920q-15 42-2 132q1-7 7-44q0-3 7-43q1-4 4-8q-1-1-1-2q-1-2-1-3q-1-22-13-36q0 1-1 2v2zm-124 661q135-54 284-81q-2-1-13-9.5t-16-13.5q-76-67-127-176q-27 86-83 197q-30 56-45 83zm646-16q-24-24-140-24q76 28 124 28q14 0 18-1q0-1-2-3z" /></svg>
                  </vx-tooltip>

                </div>
              </div>
            </div>
          </div>

          <!-- SCHEDULE -->
          <component :is="scrollbarTag" class="email-content-scroll-area" :settings="settings" ref="mailListPS" :key="$vs.rtl">
            <div class="vs-con-loading__container" id="loading">

              <vs-table v-for="(table, tIndex) in tables" :key="tIndex" :data="selectedDays" class="bordered" hoverFlat style="overflow: -webkit-paged-y;">
                <template slot="thead">
                  <vs-th style="font-size: 11px; width: 85px"><span>HORÁRIO</span></vs-th>
                  <vs-th style="font-size: 11px; min-width: 126px" v-for="(hr, i) in handleRange(table)" :key="i">
                      {{weekDays[selectedDays[hr - 1].weekdayPosition]}} {{ formatDate(selectedDays[hr - 1].id) }}
                  </vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr v-for="(hour, i) in hours" :key="i" style="font-size: 12px; opacity: 1 !important">
                    <vs-td style="text-align:right"><span class="cursor-default font-semibold">{{hour.slice(0, 5)}}</span></vs-td>
                    <vs-td v-for="(hr, i) in handleRange(table)" :key="i" class="cursor-pointer" :style="fillSchedule(selectedDays[hr - 1], hour).length && fillSchedule(selectedDays[hr - 1], hour)[0].bg || ''"
                      @click.native="addSchedule(selectedDays[hr - 1], hour)">
                        <feather-icon v-for="(holiday, hd) in selectedDays[hr - 1].holidaySchedule" :key="`${hd}_holiday`" :title="holiday.holidayName" class="mr-1" icon="StarIcon" svgClasses="w-4 h-4" />
                        <div v-for="(schedule, idx) in fillSchedule(selectedDays[hr - 1], hour)" :key="`${idx}_schedule`" class="text-black hover:text-primary"
                          style="margin-top: -7px;" @contextmenu.prevent="contextMenu($event, hr - 1, hour)">
                          <span class="font-semibold" style="font-size: 10px;">{{ schedule.turma.code }}</span> <br>
                          <div class="flex items-center">
                            <span>{{ schedule.module.abbreviation }} ({{ schedule.module.class_hour }}h)</span>
                          </div>

                        </div>
                    </vs-td>
                  </vs-tr>

                  <vs-tr style="opacity: 1 !important; border: 10px">
                    <vs-td :colspan="handleRange(table).length + 1" style="text-align:right">
                      <vs-divider></vs-divider>
                    </vs-td>
                  </vs-tr>

                </template>
              </vs-table>
            </div>
            <div v-if="!tables" class="mt-6 flex flex-wrap items-center justify-center">
              <vs-alert class="w-5/6" style="height: -webkit-fill-available;" color="warning" title="Defina o período" :active="!tables">
                Selecione a(s) data(s) no calendário ou defina um intervalo de datas para ver
                os agendamentos conforme o filtro superior.
              </vs-alert>
            </div>
          </component>
        </div>

        <!-- EMAIL VIEW SIDEBAR -->
        <schedule-view
            :flag            ="flag"
            :turma           ="detailTurma"
            :schedules       ="detailSchedules"
            :isSidebarActive ="isSidebarActive"
            @newTurma        ="pushTurmaOption"
            @editTurma       ="editTurma"
            @deleteTurma     ="deleteTurma"
            @closeSidebar    ="closeViewSidebar">
        </schedule-view>
    </div>
</template>

<script>

import _                    from 'lodash'
import { VueContext }       from 'vue-context'
import moment               from 'moment'

import ScheduleSidebar      from './TheoreticalClassSidebar.vue'
import ScheduleView         from './TheoreticalClassView.vue'
import VuePerfectScrollbar  from 'vue-perfect-scrollbar'

import moduleScheduleTheoreticalClass from '@/store/schedule-theoretical-class/moduleScheduleTheoreticalClass.js'
import moduleEmployee       from '@/store/employee/moduleEmployee.js'
import moduleClassroom      from '@/store/classroom/moduleClassroom.js'
import moduleTurma          from '@/store/turma/moduleTurma.js'

const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')

export default {
  data () {
    return {
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,
      orderBy: 'student',

      flag                 : 'scheduleDetail',
      contextCopy          : false,
      contextPaste         : false,
      contextMenuIdx       : null,
      contextMenuHour      : null,
      selectedDay          : null,
      selectedHour         : null,
      copy                 : [], // COPIAR E COLAR

      isSidebarActive      : false,
      showThread           : false,
      clickNotClose        : true,
      isEmailSidebarActive : true,
      settings             : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      weekDays: [0, 'SEG.', 'TER.', 'QUA.', 'QUI.', 'SEX.', 'SÁB.', 'DOM.'],
      students: [],
      detailSchedules: [], // AGENDAMENTOS ENVIADOS PARA VIEW (DETALHAMENTO)
      employee_id: null,
      classroom: null,
      type: 'theoretical',
      status: 'Programada', //Concluído, Não Concluído
      turma: null,
      turmaOptions: [],
      course: null,
      modules: [],
      moduleCourse: null,
      typeServiceOptions: [
        { label: '1ª Habilitação',        value: '1ª Habilitação'      },
        { label: 'Adição de categoria',   value: 'Adição de categoria' },
        { label: 'Mudança de categoria',  value: 'Mudança de categoria'},
        { label: 'Permissão ACC',         value: 'Permissão ACC'       },
        { label: 'Aula avulsa',           value: 'Aula avulsa'         }
      ],
      reportFirstPeriod: '',
      reportLastPeriod: '',
      periodError: false
    }
  },
  watch: {
    selectedDays (value) {
      if (value.length) {
        this.openLoading()
        this.debouncedGetFilterSchedule()
      }
      // AO TROCAR O PERÍODO DE DATAS DO CALENDÁRIO ZERO O PERÍODO MANUAL DO RELATÓRIO
      this.reportFirstPeriod = ''
      this.reportLastPeriod = ''
    },
    isSidebarActive (value) {
      if (!value) {
        this.showThread = false
        setTimeout(() => { this.detailSchedules.length = 0 }, 500)
      }
    },
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    turmaVuex () {
      return this.$store.state.turma.turma
    },
    detailTurma () {
      return this.turmaOptions.find(t => t.value === this.turma)
    },
    selectedDays () {
      return this.$store.state.scheduleTheoreticalClass ? this.$store.state.scheduleTheoreticalClass.selectedDays : []
    },
    periods () {
      return this.$store.state.scheduleTheoreticalClass.periods // 1 MATUTINO 2 VESPERTINO 3 NOTURNO
    },
    hours () {
      const periods = this.periods
      const h = this.$store.getters.scheduleClassTimes(null)
      const res = h.filter(function (eachElem, index) {
        return periods.indexOf(index) !== -1
      }).flat()
      return res
    },
    tables () {
      return Math.ceil(this.selectedDays.length / 6) // CONTROLE DE COLUNAS POR TABELA DE DATAS (7 COLUNAS POR TABELA)
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    classrooms () {
      return this.$store.getters['classroom/forSelect']
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    doRelatorioPdf () {
      const self = this
      this.$vs.dialog({
        type: 'confirm',
        color:'success',
        title:'Formato',
        acceptText: 'Retrato',
        cancelText: 'Paisagem',
        text:'Selecione a orientação da página',
        accept: self.printPortrait,
        cancel: self.printLandscait
      })
    },
    printPortrait () {
      this.printFormat = 'portrait'
      this.print('PDF')
    },
    printLandscait () {
      this.printFormat = 'landscape'
      this.print('PDF')
    },
    openLoading () {
      this.$vs.loading({
        container: '#loading',
        scale: 0.8
      })
    },
    closeLoading () {
      this.$vs.loading.close('#loading > .con-vs-loading')
    },

    contextMenu (event, idx, hour = null) {
      this.contextMenuIdx = idx
      this.contextMenuHour = hour
      this.contextPaste = !!this.copy.length // sem grupo copiado não mostro colar
      this.contextCopy = !!hour
      this.$refs.menu.open(event)
    },
    contextMenuClicked (action) {
      if (action === 'DELETE') {
        this.confirmDelete()
      } else if (action === 'COPY') {
        this.copyTurma()
      } else if (action === 'PASTE') {
        this.pasteTurma()
      }
    },
    viewSchedule (selectedDay, hour, flag) {
      this.flag = flag
      const format = 'h:mm:ss'
      const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
      const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

      if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) this.detailSchedules = selectedDay.turmaSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
      else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) this.detailSchedules = selectedDay.employeeSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
      else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) this.detailSchedules = selectedDay.classroomSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
      this.scheduleDetails()
    },
    handleClassroom () {
      this.flag = 'handleClassroom'
      this.scheduleDetails()
    },
    createTurma () {
      this.flag = 'newTurma'
      this.scheduleDetails()
    },
    updateTurma () {
      this.flag = 'updateTurma'
      this.scheduleDetails()
    },
    async seeTurma () {
      this.flag = 'detailsTurma'
      await this.$store.dispatch('turma/edit', this.detailTurma.value)
      this.scheduleDetails()
    },
    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async print (type) {
      const selectedDays = this.selectedDays.map(day => day.id)
      const reportFirstPeriod = this.reportFirstPeriod || selectedDays[0]
      const reportLastPeriod = this.reportLastPeriod || selectedDays[selectedDays.length - 1]

      if (reportFirstPeriod && reportLastPeriod && moment(reportFirstPeriod).year() > 1000 && moment(reportLastPeriod).year() > 1000) {
        const difDays = moment(reportLastPeriod).diff(moment(reportFirstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial é maior que o final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        this.$vs.loading()
        this.showPdfTitle = 'Relatório de Agendamentos de Aulas Teóricas'
        const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/schedule/theoreticalClass/report`, {
          firstPeriod: reportFirstPeriod,
          lastPeriod: reportLastPeriod,
          orderBy: this.orderBy,
          format: type,
          page: this.printFormat,
          payload: {
            students: this.student_id ? [this.student_id] : [],
            employees: this.employee_id ? [this.employee_id] : [],
            turmas: this.turma ? [this.turma] : [],
            status: null
          }
        }, { responseType: type === 'EXCEL' ? 'arraybuffer' : null })

        if (type === 'PDF') {
          this.srcPdf = `data:application/pdf;base64,${resp.data}`
          this.popupShowPdf = true
        } else if (type === 'EXCEL') {
          const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          this.makeBlobDoc(blob, this.showPdfTitle)
        }
        this.$nextTick(() => { this.$vs.loading.close() })
      } else {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Defina um período.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    confirmDelete () {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_theoretical')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja excluir este agendamento?',
        accept: this.deleteSchedule,
        acceptText: 'Excluir',
        cancelText: 'Cancelar'
      })
    },
    async deleteSchedule () {
      try {
        this.$vs.loading()
        let scheduleGroup = []
        const selectedDay = this.selectedDays[this.contextMenuIdx]
        if (selectedDay.turmaSchedule && selectedDay.turmaSchedule.length) scheduleGroup = selectedDay.turmaSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.employeeSchedule && selectedDay.employeeSchedule.length) scheduleGroup = selectedDay.employeeSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        else if (selectedDay.classroomSchedule && selectedDay.classroomSchedule.length) scheduleGroup = selectedDay.classroomSchedule.filter(a => a.start_time === `${this.contextMenuHour.slice(0, 5)}:00`)
        const scheduleGroupIds = scheduleGroup.map(a => a.id)
        await this.$store.dispatch('scheduleTheoreticalClass/delete', scheduleGroupIds)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Excluídos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.closeViewSidebar()
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    verifyWarnings (selectedDay) {
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP
      const counter = {}
      for (let index = 0; index < selectedDay.studentSchedule.length; index++) {
        const obj = selectedDay.studentSchedule[index]
        counter[obj.category] = (counter[obj.category] || 0) + 1
        if (counter[obj.category] >= 3 && obj.category === this.category) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: `<strong>Este aluno já possui 3 agendamentos na categoria ${obj.category} para o dia selecionado.</strong>`,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning',
            position:'top-center'
          })
          return 'GO' // STOP PARA PARAR NÃO PERMITINDO O AGENDAMENTO
        }
      }
      //  BLOCO QUE CHECA SE JÁ EXISTEM 3 MARCAÇÕES DA MESMA CATEGORIA NO DIA SELECIONADO
      //  EVITA UMA 4ª MARCAÇÃO SE RETORNAR STOP
    },
    async addSchedule (selectedDay, hour, context = false) {
      const schedule = this.fillSchedule(selectedDay, hour)
      if (schedule.length && !context) {
        this.viewSchedule(selectedDay, hour, 'scheduleDetail') // SE EXISTIR AGENDA NESTE DIA NÃO CADASTRA E SIM MOSTRA O AGENDAMENTO
        return                               // (PARA CLIQUES NAS BORDAS DA CÉLULA. OCORRE SOMENTE EM AGENDAMENTOS ÚNICOS ONDE A DIV NÃO COBRE TODO O TD)
      }

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_schedule_theoretical')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (!this.turma || !this.employee_id || !this.moduleCourse || !this.classroom) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: 'Selecione <br> <strong>TURMA, INSTRUTOR, DISCIPLINA e SALA</strong> <br> para o agendamento.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        return
      }

      //  CHECA AVISOS
      // if (this.verifyWarnings(selectedDay) === 'STOP') return
      //  CHECA AVISOS

      try {
        this.$vs.loading()
        const payload = {
          type: this.type,
          turma_id: this.turma,
          module_id: this.moduleCourse,
          classroom_id: this.classroom ? this.classroom.id : null,
          employee_id: this.employee_id,
          start_date: selectedDay.id,
          start_time: `${hour.slice(0, 5)}:00`,
          end_time: `${hour.slice(6, 11)}:00`
        }

        await this.$store.dispatch('scheduleTheoreticalClass/store', payload)

        this.$vs.loading.close()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    formatDate (date) {
      if (!date) return ''
      const [YYYY, MM, DD] = (date.slice(0, 10)).split('-')
      return `${DD}/${MM}/${YYYY}`
    },
    handleRange (row, hoursPerRow = 6) {
      if (row === this.tables && this.selectedDays.length % hoursPerRow > 0) {
        return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) - (hoursPerRow - 1) + (this.selectedDays.length % hoursPerRow))
      }
      return _.range((row * hoursPerRow) - (hoursPerRow - 1), (row * hoursPerRow) + 1)
    },
    changeEmployee () {
      this.filterSchedule()
    },
    changeClassroom () {
      this.filterSchedule()
      this.checkCalssRommCapacity()
    },
    changeTurma () {
      this.moduleCourse = null
      this.modules.splice(0)
      const turma = this.turmaOptions.find(t => t.value === this.turma)
      if (turma) {
        for (const k in turma.data.course.modules) {
          this.modules.push({
            value: turma.data.course.modules[k].id,
            label: `${turma.data.course.modules[k].title} (${turma.data.course.modules[k].class_hour}h)`,
            data: turma.data.course.modules[k]
          })
        }
        this.checkCalssRommCapacity()
      }
      // REDESENHA A TABELA E PREENCHE COM OS AGENDAMENTOS
      this.filterSchedule()
    },
    checkCalssRommCapacity () {
      const turma = this.turmaOptions.find(t => t.value === this.turma)
      if (this.classroom && turma) {
        if (this.classroom.capacity - turma.data.students.length === 0) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'Limite de capacidade da sala ATINGIDO.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else if (this.classroom.capacity - turma.data.students.length < 0) {
          this.$vs.notify({
            time: 6000,
            title: 'ATENÇÃO',
            text: 'Limite de capacidade da sala SUPERADO.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // REDESENHA A TABELA E PREENCHE COM OS AGENDAMENTOS
    async filterSchedule () {
      // CASO NÃO ENVIE FILTRO ESVAZIA A AGENDA
      if (!this.turma && !this.employee_id && !this.moduleCourse && !this.classroom) {
        this.$store.commit('scheduleTheoreticalClass/SET_HOLIDAY_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_TURMA_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_EMPLOYEE_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/SET_CLASSROOM_SCHEDULES', [])
        this.$store.commit('scheduleTheoreticalClass/FILL_SELECTED_DAYS')
        this.closeLoading()
        return
      }
      try {
        this.openLoading()
        const payload = {
          getByDate: true,
          getByPeriod: false,
          type: this.type,
          dates: this.selectedDays.map(day => day.id),
          turma_id: this.turma,
          module_id: this.moduleCourse,
          employee_id: this.employee_id,
          classroom_id: this.classroom ? this.classroom.id : null
        }
        await this.$store.dispatch('scheduleTheoreticalClass/fetchFilter', payload) // BUSCA OS AGENDAMENTOS NA API CONFORME FILTRO
        this.$store.commit('scheduleTheoreticalClass/FILL_SELECTED_DAYS')           // COLOCA OS DADOS DOS AGENDAMENTOS EM SELECEDDAYS PARA PREENCHER A TABELA DE AGENDAS
        this.closeLoading()
      } catch (error) { console.error(error) }
    },

    fillSchedule (schedule, hour) {
      if (schedule.turmaSchedule.length || schedule.employeeSchedule.length || schedule.classroomSchedule.length) {
        const format = 'h:mm:ss'
        const beforeTime = moment(`${hour.slice(0, 5)}:00`, format)
        const afterTime = moment(`${hour.slice(6, 11)}:00`, format)

        const turmaSchedule = schedule.turmaSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (turmaSchedule.length) {
          /**
           * IMPORTANTE
           * AQUI EU TENHO AS AGENDAS DE TODOS OS PARTICIPANTES DO CURSO NO MEMSO DIA E HORA
           * COMO ESTOU MARCANDO APENAS A TURMA E DISCIPLINA, OU SEJA, SE TEM OU NÃO, NÃO LISTO TODAS AS AGENDAS, APENAS
           * MARCO A DATA/HORA COMO BLOQUEADA POR UMA TURMA, POR ISSO TENTO EVITAD LOOPS DESNECESSÁRIOS
           * RETORNANDO SOMENTE A PRIMEIRA AGENDA.
           * NOTE QUE TAMBÉM PINTO O BG SOMENTE DA 1ª AGENDA
           */
          turmaSchedule[0].bg = 'background-color: rgba(21, 132, 130, 0.3);' // #158482
          return [turmaSchedule[0]]
        }

        // O MESMO PARA INSTRUTORES
        const employeeSchedule = schedule.employeeSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (employeeSchedule.length) {
          employeeSchedule[0].bg = 'background-color: rgba(255, 159, 67, 0.3);' // #FF9F43
          return [employeeSchedule[0]]
        }

        // O MESMO PARA SALAS
        const classroomSchedule = schedule.classroomSchedule.filter(e => moment(e.start_time, format).isBetween(beforeTime, afterTime,  undefined, '[)'))
        if (classroomSchedule.length) {
          classroomSchedule[0].bg = 'background-color: rgba(234, 84, 85, 0.3);' // #EA5455
          return [classroomSchedule[0]]
        }
      }
      return []
    },

    scheduleDetails () {
      this.isSidebarActive = true
    },
    fetchStudentOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'STUDENT', with: ['schedules'] })
          .then(function (response) {
            this.studentOptions = []
            for (const k in response.data) {
              this.studentOptions.push({
                value:  response.data[k].id,
                label:  `${response.data[k].name} ${response.data[k].cpf || ''}`,
                data:   response.data[k]
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    },

    fetchTurmaOptions (search, loading) {
      if (search.length) {
        loading(true)
        this.$http.post(`${process.env.VUE_APP_API_URL}/turma/search`, { kw: search })
          .then(function (response) {
            this.turmaOptions.length = 0
            for (const k in response.data) {
              this.turmaOptions.push({
                value:  response.data[k].id,
                label:  response.data[k].name,
                data:   response.data[k]
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },

    closeViewSidebar () {
      this.isSidebarActive = false
    },
    pushTurmaOption () {
      this.turmaOptions.push({
        value: this.turmaVuex.id,
        label: this.turmaVuex.name,
        data:  this.turmaVuex
      })
      this.turma = this.turmaVuex.id
      this.changeTurma()
    },
    editTurma () {
      // ESTE BLOCO SERVE PARA ATUALIZAR O SELECT DAS TURMA Q TBÉM ATUALIZA AS DISCIPLINAS
      const idx = this.turmaOptions.findIndex(a => a.value === this.turmaVuex.id)
      if (idx >= 0) this.turmaOptions.splice(idx, 1)
      this.turma = null
      this.pushTurmaOption()
    },
    deleteTurma (id) {
      // ESTE BLOCO SERVE PARA ATUALIZAR O SELECT DAS TURMA Q TBÉM ATUALIZA AS DISCIPLINAS
      const idx = this.turmaOptions.findIndex(a => a.value === id)
      if (idx >= 0) this.turmaOptions.splice(idx, 1)
      this.turma = null
      this.changeTurma()
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isEmailSidebarActive = this.clickNotClose = false
      } else {
        this.isEmailSidebarActive = this.clickNotClose = true
      }
    },
    toggleEmailSidebar (value = false) {
      if (!value) {
        this.closeViewSidebar()
        if (this.clickNotClose) {
          return
        }
      }
      this.isEmailSidebarActive = value
    }
  },
  components: {
    // MailItem,
    ScheduleSidebar,
    ScheduleView,
    VuePerfectScrollbar,
    VueContext,
    vSelect
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchStudentOptions, 600)
    this.debouncedGetSearchTurma = _.debounce(this.fetchTurmaOptions, 600)
    this.debouncedGetFilterSchedule = _.debounce(this.filterSchedule, 1000)

    if (!moduleScheduleTheoreticalClass.isRegistered) {
      this.$store.registerModule('scheduleTheoreticalClass', moduleScheduleTheoreticalClass)
      moduleScheduleTheoreticalClass.isRegistered = true
    }

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleClassroom.isRegistered) {
      this.$store.registerModule('classroom', moduleClassroom)
      moduleClassroom.isRegistered = true
    }
    this.$store.dispatch('classroom/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleTurma.isRegistered) {
      this.$store.registerModule('turma', moduleTurma)
      moduleTurma.isRegistered = true
    }

    this.setSidebarWidth()
    this.filterSchedule() // INICIA COM ESTE MÉTODO PARA ESVAZIAR LIXO DO STATE
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";

  .popup80 .vs-popup {
    width: 80% !important;
  }

#schedule-practical th .vs-table-text {
    cursor: default;
}
.svg-hover:hover {
  fill: #158482;
}
.svg-hover {
  fill: #626262;
}
#schedule-practical td {
  border: 1px solid #c0c0c0;
  line-height: 1;
}
#schedule-practical td:hover {
  border-bottom: 1px solid #353434;
}
#schedule-practical td:first-child:hover {
  border-bottom: 1px solid #c0c0c0;
}
#schedule-practical .vs-con-table td:last-child {
  border-right: 1px solid transparent;
}
#schedule-practical .vs-table--thead {
  border: 1px solid transparent;
}
.icon {
  font-size: 16px;
}
#schedule-practical .vs-con-table .vs-con-tbody .vs-table--tbody-table .tr-values .vs-table--td {
    padding: 6px;
}
#schedule-practical .ps__scrollbar-y-rail {
  z-index: 99999;
}
</style>
