var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            title: "Cadastrar Sala de Aula",
            active: _vm.popupAddClassroom,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupAddClassroom = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-3/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Nome da Sala",
                    "data-vv-scope": "classroom",
                    "data-vv-as": "Nome Fantasia",
                    name: "class_name",
                  },
                  model: {
                    value: _vm.classroomEditable.class_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.classroomEditable, "class_name", $$v)
                    },
                    expression: "classroomEditable.class_name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("classroom.class_name"),
                        expression: "errors.has('classroom.class_name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("classroom.class_name")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "number",
                    label: "Capacidade",
                    "data-vv-scope": "classroom",
                    "data-vv-as": "Capacidade",
                    name: "capacity",
                  },
                  model: {
                    value: _vm.classroomEditable.capacity,
                    callback: function ($$v) {
                      _vm.$set(_vm.classroomEditable, "capacity", $$v)
                    },
                    expression: "classroomEditable.capacity",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("classroom.capacity"),
                        expression: "errors.has('classroom.capacity')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("classroom.capacity")))]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateClassroomForm },
                      on: { click: _vm.storeClassroom },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupAddClassroom = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            title: "Editar Sala de Aula",
            active: _vm.popupEditClassroom,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupEditClassroom = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-3/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Nome da Sala",
                    "data-vv-scope": "classroom",
                    "data-vv-as": "Nome Fantasia",
                    name: "class_name",
                  },
                  model: {
                    value: _vm.classroomEditable.class_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.classroomEditable, "class_name", $$v)
                    },
                    expression: "classroomEditable.class_name",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("classroom.class_name"),
                        expression: "errors.has('classroom.class_name')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("classroom.class_name")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "number",
                    label: "Capacidade",
                    "data-vv-scope": "classroom",
                    "data-vv-as": "Capacidade",
                    name: "capacity",
                  },
                  model: {
                    value: _vm.classroomEditable.capacity,
                    callback: function ($$v) {
                      _vm.$set(_vm.classroomEditable, "capacity", $$v)
                    },
                    expression: "classroomEditable.capacity",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("classroom.capacity"),
                        expression: "errors.has('classroom.capacity')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("classroom.capacity")))]
                ),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateClassroomForm },
                      on: { click: _vm.updateClassroom },
                    },
                    [_vm._v("Alterar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupEditClassroom = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#schedule-practical",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _vm.localSchedules.length && _vm.flag === "scheduleDetail"
            ? _c(
                "div",
                { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                    },
                    [
                      _c("div", { staticClass: "flex mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer hover:text-primary mr-4",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ArrowRightIcon"
                                  : "ArrowLeftIcon",
                                "svg-classes": "w-6 h-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeSidebar")
                                },
                              },
                            }),
                            _c("h4", [_vm._v("DETALHAMENTO DESTE ENCONTRO")]),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                        _c("div", {
                          staticClass:
                            "email__actions--single flex items-baseline",
                        }),
                      ]),
                    ]
                  ),
                  _c(
                    _vm.scrollbarTag,
                    {
                      key: _vm.$vs.rtl,
                      tag: "component",
                      staticClass:
                        "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _vm.isSidebarActive
                        ? _c("div", [
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("schedule-card", {
                                    attrs: { schedule: _vm.localSchedules[0] },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "vx-row",
                                staticStyle: { "margin-top": "2.2rem" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "vx-col w-full" },
                                  [
                                    _c(
                                      "vx-card",
                                      [
                                        _c("div", { staticClass: "vx-row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
                                            },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "mb-1" },
                                                [
                                                  _vm._v(
                                                    "Participantes do encontro"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "vx-tooltip",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    text: "Alterar Status",
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "vs-dropdown",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        "vs-custom-content": "",
                                                        "vs-trigger-click": "",
                                                      },
                                                    },
                                                    [
                                                      _c("feather-icon", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          icon: "FolderIcon",
                                                          "svg-classes":
                                                            "h-6 w-6",
                                                        },
                                                      }),
                                                      _c(
                                                        "vs-dropdown-menu",
                                                        { staticClass: "w-52" },
                                                        [
                                                          _c(
                                                            "vs-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateStatus(
                                                                      "Presente"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-4 w-4 inline-block rounded-full mr-3 bg-success",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Presente"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "vs-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateStatus(
                                                                      "Ausente"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-4 w-4 inline-block rounded-full mr-3 bg-danger",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Ausente"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "vs-dropdown-item",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateStatus(
                                                                      "Programada"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "flex items-center",
                                                                },
                                                                [
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "h-4 w-4 inline-block rounded-full mr-3 border-2 border-solid border-secondary",
                                                                  }),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Programada"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vx-tooltip",
                                                {
                                                  staticClass: "ml-4",
                                                  attrs: {
                                                    text: "Deletar Selecionados Deste Encontro",
                                                    position: "top",
                                                  },
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    staticClass:
                                                      "cursor-pointer",
                                                    attrs: {
                                                      icon: "TrashIcon",
                                                      "svg-classes": "h-6 w-6",
                                                    },
                                                    on: {
                                                      click: _vm.confirmDelete,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm.localSchedules.length
                                          ? _c(
                                              "vs-table",
                                              {
                                                staticClass: "bordered",
                                                staticStyle: {
                                                  overflow: "-webkit-paged-y",
                                                },
                                                attrs: {
                                                  data: _vm.localSchedulesOrdered,
                                                  hoverFlat: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ data }) {
                                                        return _vm._l(
                                                          data,
                                                          function (
                                                            schedule,
                                                            indextr
                                                          ) {
                                                            return _c(
                                                              "vs-tr",
                                                              {
                                                                key: indextr,
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "12px",
                                                                  opacity:
                                                                    "1 !important",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "vs-td",
                                                                  [
                                                                    _c(
                                                                      "vs-checkbox",
                                                                      {
                                                                        staticClass:
                                                                          "vs-checkbox-small ml-0 mr-1",
                                                                        attrs: {
                                                                          "vs-value":
                                                                            schedule,
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm.selectedSchedules,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedSchedules =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "selectedSchedules",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("vs-td", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-default font-semibold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          schedule
                                                                            .student
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                                _c("vs-td", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-default font-semibold",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          schedule
                                                                            .employee
                                                                            .name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                                _c("vs-td", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-default font-semibold flex items-center",
                                                                      class: `text-${
                                                                        schedule.status ===
                                                                        "Presente"
                                                                          ? "success"
                                                                          : schedule.status ===
                                                                            "Ausente"
                                                                          ? "danger"
                                                                          : schedule.status ===
                                                                            "Programada"
                                                                          ? "secondary"
                                                                          : ""
                                                                      }`,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm._f(
                                                                              "scheduleStatus"
                                                                            )(
                                                                              schedule.status
                                                                            )
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        )
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2073141254
                                                ),
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "thead" },
                                                  [
                                                    _c(
                                                      "vs-th",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "11px",
                                                          width: "40px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "vs-checkbox",
                                                          {
                                                            staticClass:
                                                              "select-all-chexkbox",
                                                            attrs: {
                                                              disabled:
                                                                !_vm
                                                                  .localSchedules
                                                                  .length,
                                                              "icon-pack":
                                                                "feather",
                                                              icon: _vm.selectAllIcon,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectAllCheckBox,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectAllCheckBox =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectAllCheckBox",
                                                            },
                                                          },
                                                          [_vm._v("Todos")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "vs-th",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "11px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("ALUNO"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-th",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "11px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("INSTRUTOR"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "vs-th",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "11px",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("STATUS"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "mail-sidebar-content px-0 sm:pb-6 h-full" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center email-header justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                    },
                    [
                      _c("div", { staticClass: "flex mb-4" }, [
                        _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c("feather-icon", {
                              staticClass:
                                "cursor-pointer hover:text-primary mr-4",
                              attrs: {
                                icon: _vm.$vs.rtl
                                  ? "ArrowRightIcon"
                                  : "ArrowLeftIcon",
                                "svg-classes": "w-6 h-6",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeSidebar")
                                },
                              },
                            }),
                            _c("h4", [_vm._v("VOLTAR")]),
                          ],
                          1
                        ),
                      ]),
                      _vm.flag === "updateTurma" || _vm.flag === "detailsTurma"
                        ? _c("div", { staticClass: "ml-10 mb-4 mt-1" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "email__actions--single flex items-baseline",
                              },
                              [
                                _c(
                                  "vx-tooltip",
                                  {
                                    staticClass: "ml-4",
                                    attrs: {
                                      text: "Deletar a Turma e Seus Agendamentos",
                                      position: "top",
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass:
                                        "cursor-pointer hover:text-danger",
                                      attrs: {
                                        icon: "TrashIcon",
                                        "svg-classes": "h-6 w-6",
                                      },
                                      on: { click: _vm.confirmDeleteTurma },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "vx-row",
                      staticStyle: { "margin-top": "0.5rem" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full md:px-8 md:pb-8 pt-4 px-6 pb-6",
                        },
                        [
                          _vm.flag === "updateTurma"
                            ? _c(
                                "vx-card",
                                [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid",
                                      },
                                      [
                                        _c("h5", { staticClass: "mb-1" }, [
                                          _vm._v("Editando uma turma"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-1/2 w-full mt-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.initial",
                                              value: "required",
                                              expression: "'required'",
                                              modifiers: { initial: true },
                                            },
                                          ],
                                          staticClass: "w-full mt-0",
                                          attrs: {
                                            label: "Nome da Turma",
                                            "data-vv-scope": "updateTurma",
                                            "data-vv-as": "Nome da Turma",
                                            name: "name",
                                          },
                                          model: {
                                            value: _vm.localTurma.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localTurma,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "localTurma.name",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "updateTurma.name"
                                                  ),
                                                expression:
                                                  "errors.has('updateTurma.name')",
                                              },
                                            ],
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "updateTurma.name"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-1/2 w-full mt-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "vs-input--label" },
                                          [_vm._v("Curso")]
                                        ),
                                        _c("v-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.initial",
                                              value: "required",
                                              expression: "'required'",
                                              modifiers: { initial: true },
                                            },
                                          ],
                                          staticStyle: {
                                            "background-color": "white",
                                          },
                                          attrs: {
                                            clearable: true,
                                            reduce: (option) => option.value,
                                            options: _vm.courses,
                                            "data-vv-scope": "updateTurma",
                                            "data-vv-as": "Curso",
                                            name: "course",
                                            placeholder: "Selecione",
                                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "no-options",
                                                fn: function ({}) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    Nenhum resultado encontrado.\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1392614326
                                          ),
                                          model: {
                                            value: _vm.localTurma.course_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localTurma,
                                                "course_id",
                                                $$v
                                              )
                                            },
                                            expression: "localTurma.course_id",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "updateTurma.course"
                                                  ),
                                                expression:
                                                  "errors.has('updateTurma.course')",
                                              },
                                            ],
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "updateTurma.course"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-full w-full mt-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "vs-input--label" },
                                          [_vm._v("Aluno(s)")]
                                        ),
                                        _c("v-select", {
                                          staticStyle: {
                                            "background-color": "white",
                                          },
                                          attrs: {
                                            clearable: true,
                                            filterable: false,
                                            options: _vm.studentOptions,
                                            placeholder:
                                              "Digite CPF ou nome do aluno...",
                                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          },
                                          on: {
                                            input: _vm.addStudent,
                                            search:
                                              _vm.debouncedGetSearchStudent,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "no-options",
                                                fn: function ({}) {
                                                  return [
                                                    _vm._v(
                                                      "\n                    Nenhum resultado encontrado.\n                  "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1392614326
                                          ),
                                          model: {
                                            value: _vm.student,
                                            callback: function ($$v) {
                                              _vm.student = $$v
                                            },
                                            expression: "student",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.sortStudentList,
                                      function (student, idx) {
                                        return _c("li", { key: idx }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mt-2 flex flex-wrap items-center",
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "TrashIcon",
                                                  svgClasses:
                                                    "hover:text-danger cursor-pointer stroke-current w-5 h-5",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeStudent(
                                                      student
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "font-semibold mt-1 ml-2 mr-2",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      student.data &&
                                                        student.data.name
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "mt-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      student.data &&
                                                        student.data.cpf
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _c("vs-divider"),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-4 flex flex-wrap items-center justify-end",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-4",
                                                attrs: {
                                                  disabled:
                                                    !_vm.validateUpdateTurma,
                                                },
                                                on: { click: _vm.updateTurma },
                                              },
                                              [_vm._v("Alterar Turma")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm.flag === "newTurma"
                            ? _c(
                                "vx-card",
                                [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid",
                                      },
                                      [
                                        _c("h5", { staticClass: "mb-1" }, [
                                          _vm._v("Criando uma nova turma"),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-1/2 w-full mt-2",
                                      },
                                      [
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.initial",
                                              value: "required",
                                              expression: "'required'",
                                              modifiers: { initial: true },
                                            },
                                          ],
                                          staticClass: "w-full mt-0",
                                          attrs: {
                                            label: "Nome da Turma",
                                            "data-vv-scope": "newTurma",
                                            "data-vv-as": "Nome da Turma",
                                            name: "name",
                                          },
                                          model: {
                                            value: _vm.localTurma.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localTurma,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "localTurma.name",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "newTurma.name"
                                                  ),
                                                expression:
                                                  "errors.has('newTurma.name')",
                                              },
                                            ],
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "newTurma.name"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-1/2 w-full mt-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "vs-input--label" },
                                          [_vm._v("Curso")]
                                        ),
                                        _c("v-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate.initial",
                                              value: "required",
                                              expression: "'required'",
                                              modifiers: { initial: true },
                                            },
                                          ],
                                          staticStyle: {
                                            "background-color": "white",
                                          },
                                          attrs: {
                                            clearable: true,
                                            reduce: (option) => option.value,
                                            options: _vm.courses,
                                            "data-vv-scope": "newTurma",
                                            "data-vv-as": "Curso",
                                            name: "course",
                                            placeholder: "Selecione",
                                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                    Nenhum resultado encontrado.\n                  "
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.localTurma.course_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.localTurma,
                                                "course_id",
                                                $$v
                                              )
                                            },
                                            expression: "localTurma.course_id",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.errors.has(
                                                    "newTurma.course"
                                                  ),
                                                expression:
                                                  "errors.has('newTurma.course')",
                                              },
                                            ],
                                            staticClass: "text-danger text-sm",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "newTurma.course"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-full w-full mt-2",
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "vs-input--label" },
                                          [_vm._v("Aluno(s)")]
                                        ),
                                        _c("v-select", {
                                          staticStyle: {
                                            "background-color": "white",
                                          },
                                          attrs: {
                                            clearable: true,
                                            filterable: false,
                                            options: _vm.studentOptions,
                                            placeholder:
                                              "Digite CPF ou nome do aluno...",
                                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                          },
                                          on: {
                                            input: _vm.addStudent,
                                            search:
                                              _vm.debouncedGetSearchStudent,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                    Nenhum resultado encontrado.\n                  "
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.student,
                                            callback: function ($$v) {
                                              _vm.student = $$v
                                            },
                                            expression: "student",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.sortStudentList,
                                      function (student) {
                                        return _c(
                                          "li",
                                          { key: student.data.id },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mt-2 flex flex-wrap items-center",
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "TrashIcon",
                                                    svgClasses:
                                                      "hover:text-danger cursor-pointer stroke-current w-5 h-5",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeStudent(
                                                        student
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "font-semibold mt-1 ml-2 mr-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(student.data.name)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "small",
                                                  { staticClass: "mt-1" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(student.data.cpf)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c("vs-divider"),
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-4 flex flex-wrap items-center justify-end",
                                          },
                                          [
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "ml-4",
                                                attrs: {
                                                  disabled:
                                                    !_vm.validateNewTurma,
                                                },
                                                on: { click: _vm.createTurma },
                                              },
                                              [_vm._v("Cadastrar Turma")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.flag === "detailsTurma"
                            ? _c(
                                "vx-card",
                                [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid",
                                      },
                                      [
                                        _c("h5", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "Turma " +
                                              _vm._s(_vm.turmaVuex.name) +
                                              " | " +
                                              _vm._s(_vm.turmaVuex.course.name)
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm.turmaVuex.schedules
                                    ? _c(
                                        "vs-table",
                                        {
                                          staticClass: "bordered",
                                          staticStyle: {
                                            overflow: "-webkit-paged-y",
                                          },
                                          attrs: {
                                            data: _vm.groupedTurmaSchedules,
                                            hoverFlat: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function ({ data }) {
                                                  return _vm._l(
                                                    data,
                                                    function (
                                                      schedule,
                                                      indextr
                                                    ) {
                                                      return _c(
                                                        "vs-tr",
                                                        {
                                                          key: indextr,
                                                          staticStyle: {
                                                            "font-size": "12px",
                                                            opacity:
                                                              "1 !important",
                                                          },
                                                        },
                                                        [
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "dateTime"
                                                                    )(
                                                                      schedule.date
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.start_time.slice(
                                                                      0,
                                                                      5
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.end_time.slice(
                                                                      0,
                                                                      5
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule.quantity
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule
                                                                      .module
                                                                      .title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("vs-td", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "cursor-default font-semibold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    schedule
                                                                      .employee
                                                                      .name
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3112512111
                                          ),
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "thead" },
                                            [
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [_c("span", [_vm._v("DATA")])]
                                              ),
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [_c("span", [_vm._v("1ª HA")])]
                                              ),
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("ÚLTIMA HA"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [_c("span", [_vm._v("QTD.")])]
                                              ),
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("DISCIPLINA"),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "vs-th",
                                                {
                                                  staticStyle: {
                                                    "font-size": "11px",
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("INSTRUTOR"),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.flag === "handleClassroom"
                            ? _c(
                                "vx-card",
                                [
                                  _c("div", { staticClass: "vx-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col w-full border-b border-l-0 border-r-0 border-t-0 d-theme-border-grey-light border-solid flex justify-between flex items-center",
                                      },
                                      [
                                        _c("h5", { staticClass: "mb-1" }, [
                                          _vm._v(
                                            "Gerenciamento de Salas de Aula"
                                          ),
                                        ]),
                                        _c(
                                          "vx-tooltip",
                                          {
                                            staticClass: "ml-4",
                                            attrs: {
                                              text: "Cadastrar Sala de Aula",
                                              position: "top",
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "cursor-pointer",
                                              attrs: {
                                                icon: "PlusCircleIcon",
                                                "svg-classes": "h-6 w-6",
                                              },
                                              on: { click: _vm.addClassroom },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "vs-table",
                                    {
                                      staticClass: "bordered",
                                      staticStyle: {
                                        overflow: "-webkit-paged-y",
                                      },
                                      attrs: {
                                        noDataText: "Nenhuma Sala Cadastrada.",
                                        data: _vm.classrooms,
                                        hoverFlat: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ data }) {
                                              return _vm._l(
                                                data,
                                                function (classroom, itr) {
                                                  return _c(
                                                    "vs-tr",
                                                    {
                                                      key: itr,
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        opacity: "1 !important",
                                                      },
                                                    },
                                                    [
                                                      _c("vs-td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "cursor-default font-semibold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                classroom.class_name
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c("vs-td", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "cursor-default font-semibold",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                classroom.capacity
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "vs-td",
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "Edit3Icon",
                                                              svgClasses:
                                                                "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editClassroom(
                                                                  classroom
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "Trash2Icon",
                                                              svgClasses:
                                                                "h-5 w-5 hover:text-danger cursor-pointer",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.confirmDeleteClassroom(
                                                                  classroom
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1968222443
                                      ),
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "thead" },
                                        [
                                          _c(
                                            "vs-th",
                                            {
                                              staticStyle: {
                                                "font-size": "11px",
                                              },
                                            },
                                            [_c("span", [_vm._v("SALA")])]
                                          ),
                                          _c(
                                            "vs-th",
                                            {
                                              staticStyle: {
                                                "font-size": "11px",
                                              },
                                            },
                                            [_c("span", [_vm._v("CAPACIDADE")])]
                                          ),
                                          _c(
                                            "vs-th",
                                            {
                                              staticStyle: {
                                                "font-size": "11px",
                                              },
                                            },
                                            [_c("span", [_vm._v("AÇÃO")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }