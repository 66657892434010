
export default {
  SET (state, payload) {
    state.course = Object.assign({}, state.course, payload) //precisa ser assim para manter a reatividade
  },
  REFRESH (state, payload) {
    state.courses = payload
  },
  DELETE (state, payload) {
    // const cnhIndex = state.course.findIndex((cnh) => cnh.id === payload)
    // state.course.splice(cnhIndex, 1) //precisa ser assim para manter a reatividade
  }
}
